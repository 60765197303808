import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import axios from 'axios';
import $ from 'jquery';
import form from 'jquery-form';
export default {
  data: function data() {
    return {
      equipmentId: null,
      equipmentList: [],
      humidityChart: [],
      temperatureChart: [],
      gasChart: [],
      smokeDatas: [],
      CODatas: [],
      VOCDatas: [],
      humidityDatas: [],
      temperatureDatas: [],
      gasOption: [],
      humidityOption: [],
      temperatureOption: [],
      spinning: false,
      packCount: 16
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.getEquipmentList();
    this_.initChart();
  },
  methods: {
    sortNum: function sortNum(a, b) {
      if (a.typeId == 3 && b.typeId == 3) {
        return (a.num - b.num) * 1;
      } else if (a.typeId == 3) {
        return -1;
      } else {
        return a.typeId - b.typeId;
      }
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        typeId: 3,
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentList = d.data.data;
          this_.equipmentList.sort(this_.sortNum);

          if (this_.equipmentList.length > 0) {
            this_.equipmentId = this_.equipmentList[0].id;
            this_.getData();
          }
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getData: function getData() {
      var this_ = this;
      this_.spinning = true;
      axios({
        method: 'post',
        url: '/emind/config/firefighting/data/' + this_.equipmentId + '/3'
      }).then(function (d) {
        //
        this_.spinning = false;

        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.refreshChart(d.data.data);
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    refreshChart: function refreshChart(datas) {
      var this_ = this;
      this_.packCount = datas.packCount;
      var key = '';
      var dom;
      this_.temperatureDatas = [];
      this_.humidityDatas = [];
      this_.CODatas = [];
      this_.VOCDatas = [];
      this_.smokeDatas = [];
      var dd;
      var width = 100 / this_.packCount;

      for (var i = 1; i <= this_.packCount; i++) {
        dom = $('#title-' + i);
        dom.css('width', width - 1 + '%');
        key = 'TH' + i + '_SensorCommunicationStatus';
        dom = $('#TH-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
        key = 'VOC' + i + '_SensorCommunicationStatus';
        dd = datas[key];
        dom = $('#VOC-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
        key = 'OC' + i + '_SensorCommunicationStatus';
        dd = datas[key];
        dom = $('#OC-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
        key = 'Smoke' + i + '_SensorCommunicationStatus';
        dd = datas[key];
        dom = $('#Smoke-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
        key = 'packCommunicationStatus' + i;
        dd = datas[key];
        dom = $('#PACK-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
        key = 'inputStatus' + i + '_DI';
        dd = datas[key];
        dom = $('#DI-' + i);
        dom.css('width', width - 1 + '%');
        dom.html(dd == null ? '--' : dd);
      }

      var newOp = this_.temperatureChart.getOption();
      newOp.series[0].data = this_.temperatureDatas;
      this_.temperatureChart.setOption(newOp);
      var newOp = this_.humidityChart.getOption();
      newOp.series[0].data = this_.humidityDatas;
      this_.humidityChart.setOption(newOp);
      var newOp = this_.gasChart.getOption();
      newOp.series[0].data = this_.VOCDatas;
      newOp.series[1].data = this_.CODatas;
      newOp.series[2].data = this_.smokeDatas;
      this_.gasChart.setOption(newOp);
    },
    initChart: function initChart() {
      var this_ = this;
      var dom;
      var width = 100 / this_.packCount;

      for (var i = 1; i <= this_.packCount; i++) {
        dom = $('#title-' + i);
        dom.css('width', width - 1 + '%');
        dom = $('#TH-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
        dom = $('#VOC-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
        dom = $('#OC-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
        dom = $('#Smoke-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
        dom = $('#PACK-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
        dom = $('#DI-' + i);
        dom.css('width', width - 1 + '%');
        dom.html('--');
      }

      this_.temperatureOption = {
        title: {
          textStyle: {
            color: '#E2E2E2',
            fontFamily: "微软雅黑",
            fontSize: 16
          },
          text: 'PACK温度曲线',
          subtextStyle: {
            color: '#B7B8BC',
            fontFamily: "微软雅黑"
          },
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'

          },
          formatter: function formatter(params) {
            var relVal = params[0].name + '号PACK温度（℃）：' + params[0].value[1];
            return relVal;
          }
        },
        grid: {
          left: 50,
          right: 50,
          top: '50',
          height: '60%'
        },
        xAxis: {
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '序号',
          type: 'category',
          boundaryGap: false
        },
        yAxis: {
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '温度(℃)',
          type: 'value'
        },
        series: [{
          name: '温度',
          type: 'line',
          itemStyle: {
            color: '#49AA19'
          },
          areaStyle: {
            color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(73, 170, 25, 0.3)'
            }, {
              offset: 0.5,
              color: 'rgba(73, 170, 25, 0.1)'
            }, {
              offset: 1,
              color: 'rgba(73, 170, 25, 0)'
            }])
          },
          data: this_.temperatureDatas
        }]
      };
      this_.humidityOption = {
        title: {
          textStyle: {
            color: '#E2E2E2',
            fontFamily: "微软雅黑",
            fontSize: 16
          },
          text: 'PACK湿度曲线',
          subtextStyle: {
            color: '#B7B8BC',
            fontFamily: "微软雅黑"
          },
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: function formatter(params) {
            var relVal = params[0].name + '号PACK湿度（%）：' + params[0].value[1];
            return relVal;
          }
        },
        grid: {
          left: 50,
          right: 50,
          top: '50',
          height: '60%'
        },
        xAxis: {
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '序号',
          type: 'category',
          boundaryGap: false
        },
        yAxis: [{
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          max: 100,
          min: 0,
          name: '湿度（%）',
          type: 'value'
        }],
        series: [{
          name: '湿度（%）',
          type: 'line',
          itemStyle: {
            color: '#1890FF'
          },
          areaStyle: {
            color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(24, 144, 225, 0.3)'
            }, {
              offset: 0.5,
              color: 'rgba(24, 144, 225, 0.1)'
            }, {
              offset: 1,
              color: 'rgba(24, 144, 225, 0)'
            }])
          },
          data: this_.humidityDatas
        }]
      };
      this_.gasOption = {
        title: {
          textStyle: {
            color: '#E2E2E2',
            fontFamily: "微软雅黑",
            fontSize: 16
          },
          text: 'PACK气体浓度曲线',
          subtextStyle: {
            color: '#B7B8BC',
            fontFamily: "微软雅黑"
          },
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: 50,
          right: 50,
          top: '50',
          height: '60%'
        },
        xAxis: {
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '序号',
          type: 'category',
          boundaryGap: false
        },
        yAxis: [{
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D',
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: 'PPM',
          type: 'value',
          inverse: false
        }],
        series: [{
          name: 'VOC（PPM）',
          type: 'line',
          itemStyle: {
            color: '#F26813'
          },
          areaStyle: {
            color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(242, 104, 19, 0.3)'
            }, {
              offset: 0.5,
              color: 'rgba(242, 104, 19, 0.1)'
            }, {
              offset: 1,
              color: 'rgba(242, 104, 19, 0)'
            }])
          },
          data: this_.VOCDatas
        }, {
          name: 'CO（PPM）',
          type: 'line',
          itemStyle: {
            color: '#F26813'
          },
          areaStyle: {
            color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(242, 104, 19, 0.3)'
            }, {
              offset: 0.5,
              color: 'rgba(242, 104, 19, 0.1)'
            }, {
              offset: 1,
              color: 'rgba(242, 104, 19, 0)'
            }])
          },
          data: this_.CODatas
        }, {
          name: '烟雾（PPM）',
          type: 'line',
          itemStyle: {
            color: '#F26813'
          },
          areaStyle: {
            color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(242, 104, 19, 0.3)'
            }, {
              offset: 0.5,
              color: 'rgba(242, 104, 19, 0.1)'
            }, {
              offset: 1,
              color: 'rgba(242, 104, 19, 0)'
            }])
          },
          data: this_.smokeDatas
        }]
      };
      this_.humidityChart = this_.$echarts.init(document.getElementById("chart_humidity"));
      this_.humidityChart.setOption(this_.humidityOption);
      this_.temperatureChart = this_.$echarts.init(document.getElementById("chart_temp"));
      this_.temperatureChart.setOption(this_.temperatureOption);
      this_.gasChart = this_.$echarts.init(document.getElementById("chart_gas"));
      this_.gasChart.setOption(this_.gasOption);
      setTimeout(function () {
        window.onresize = function () {
          this_.humidityChart.resize();
          this_.temperatureChart.resize();
          this_.gasChart.resize();
        };
      }, 50);
    }
  }
};